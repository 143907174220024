<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="start" class="my-2">
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Branch Number" rules="required|numeric">
        <v-text-field v-model="branch.number" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNumber')"
                      :min="0" dense outlined type="number"/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Branch Name (EN)" rules="required|min:2|max:30">
        <v-text-field v-model="branch.brand_name_en" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNameEn')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Branch Name (AR)" rules="required|min:2|max:30">
        <v-text-field v-model="branch.brand_name_ar" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.branchNameAr')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <kurcc-autocomplete :display-function="areaDisplayMethod" :filter="areaFilterByName" :items="areas"
                          :loading="fetchingAreas" :multiple="false"
                          :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.area')"
                          :selected-items.sync="branch.area" outlined/>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Address" rules="required|min:2|max:300">
        <v-text-field v-model="branch.address" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.address')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Manager Name" rules="required|min:2|max:30">
        <v-text-field v-model="branch.manager_name" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.managerName')" dense outlined/>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4" sm="6">
      <validation-provider v-slot="{ errors }" name="Manager Phone Number" rules="required">
        <v-text-field v-model="branch.manager_phone_number" :error-messages="errors"
                      :label="$vuetify.lang.t('$vuetify.pages.site.form.fields.managerPhoneNumber')"
                      dense outlined/>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'
import Branch from '@/modules/app/models/branch'
import { areaDisplayMethod, areaFilterByName } from '@/modules/site/helpers/utils'

export default {
  name: 'KurccSiteStepperStep8Content',
  components: {
    KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
  },
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  data () {
    return {
      submittingData: false,
      branch: new Branch(),
      areas: [],
      fetchingAreas: false,
      areaDisplayMethod,
      areaFilterByName
    }
  },
  watch: {
    branch: {
      handler: 'updateBranch',
      deep: true
    }
  },
  methods: {
    updateBranch () {
      const siteClone = this.$lodash.cloneDeep(this.site)
      siteClone.branch = this.branch
      this.$emit('update:site', siteClone)
    },
    getAllConfigs () {
      this.fetchingAreas = true
      this.$store.dispatch('getAllConfigs').then(res => {
        this.areas = res.areas
      }).finally(() => {
        this.fetchingAreas = false
      })
    }
  },
  created () {
    this.getAllConfigs()
  }
}
</script>
